import React, { useState, useRef } from 'react';
import utils from '../../utils';
import * as yt from './iframeApi';
import { FiVolumeX, FiVolume2 } from 'react-icons/fi';
import { FaRegPlayCircle } from 'react-icons/fa';
import ModalVideo from 'react-modal-video'


export function YoutubeBannerMobilePopup(props) {
  //const youtubeBanner = React.useRef(null);

  const [playerStatus, setPlayerStatus] = useState('loading');
  const [playerSize, setPlayerSize] = useState(null);
  const [ytPlayer, setYtPlayer] = useState(null);
  const [muted, setMuted] = useState(true);
  const countPlayedRef = useRef(0);
  const [popupOpened, setPopupOpened] = useState(false);
  const { heightSet, videoLinkOrId, playerId } = props.video;
  const videoId = yt.getYoutubeCode(videoLinkOrId);
  const posterImageUrl = yt.thumbnailMax(videoId);
  //let countPlayed = 0;
  const canPlayVideo = (typeof window !== `undefined` && typeof props.canPlayVideo === `undefined`)? window.innerWidth >= 1200 : !!props.canPlayVideo;

  const playerVars = {
    'enablejsapi': 1,
    //'autoplay': 1,
    'controls': 0,
    'loop': 1,
    'showinfo': 0,
    'rel': 0,
    'modestbranding': 1,
    'origin': typeof window !== `undefined` && window.location.origin,
    'allowfullscreen': true,
    'wmode': 'transparent'
  }

  //console.log('videoId: ' + videoId)
  //const canPlayVideo = () => window.innerWidth >= 1200
  React.useEffect(() => {
    let player = null;
    if (canPlayVideo) {
      yt.loadApi((loadJs) => {
        //console.log('yt api', playerId, loadJs)
        const init = () => {
          if (typeof window !== `undefined`) {
            player = new window.YT.Player(playerId, {
              events: {
                'onStateChange': (e) => {
                  switch (e.data) {
                    // case window.YT.PlayerState.PLAYING:
                    //   break;
                    // case window.YT.PlayerState.PAUSED:
                    //   break;
                    case window.YT.PlayerState.ENDED:
                      const countPlayed = countPlayedRef.current;
                      if (countPlayed < 3 - 1) {
                        //console.log('ended', countPlayed + 1);
                        countPlayedRef.current = countPlayed + 1;
                        player.playVideo();
                      } else {
                        setPlayerStatus('finished');
                      }
                      break;
                    default:
                  }
                },
                'onReady': (e) => {
                  //console.log('onReady');
                  player.mute();
                  player.playVideo();
                  setTimeout(() => setPlayerStatus('loaded'), 1000)
                },
                'onError': (e) => { console.log(e) }
              }
            });

            setYtPlayer(player);
          }
        }

        loadJs? init() : setTimeout(init, 100)
      })
    }
    // eslint-disable-next-line
  }, [])

  const youtubeBannerRef = React.useCallback(el => {
    setPlayerSize(getSize(el));
  }, []);

  function soundToggle() {
    if (ytPlayer){
      if (muted) {
        ytPlayer.pauseVideo();
        ytPlayer.unMute();
        ytPlayer.playVideo();
        setMuted(false);
      } else {
        ytPlayer.pauseVideo();
        ytPlayer.mute();
        ytPlayer.playVideo();
        setMuted(true);
      }
    }
  }

  function playVideo() {
    setPopupOpened(true)
  }

  return (
    <>
    <div className={utils.classNames('youtubePlayer', `youtubePlayer--${playerStatus}`, `youtubePlayer--${heightSet}`)} ref={youtubeBannerRef}>
      <div className="youtubePlayer__mask"></div>
      <div className="youtubePlayer__poster" style={{ backgroundImage: utils.css.bgUrlStyle(posterImageUrl) }}></div>
      {canPlayVideo && playerSize && <iframe className="youtubePlayer__iframe" id={playerId} width="640" height="390" title="Youtube video"
        style={{ width: `${playerSize.width}px`, height: `${playerSize.height}px`, top: `${playerSize.top}px`, left: `${playerSize.left}px` }}
        src={`https://www.youtube.com/embed/${videoId}?${utils.url.toQueryString(playerVars)}`}
        frameBorder="0"></iframe>}
      {canPlayVideo && playerStatus === 'loaded' && <div className="youtubePlayer__soundIcon" title={muted? 'Sound On': 'Sound Off'} onClick={soundToggle}>
        {muted? <FiVolume2 /> : <FiVolumeX/>}
      </div>}

      {!canPlayVideo && <div className="youtubePlayer__playIcon" title="Play" onClick={playVideo}>
      <FaRegPlayCircle />
      </div>}
    </div>
    <ModalVideo channel='youtube' isOpen={popupOpened} videoId={videoId} onClose={() => setPopupOpened(false)} />
    </>
  );
}

function getSize(container) {
  const containerSize = container ? { w: container.offsetWidth, h: container.offsetHeight } : { w: window.innerWidth, h: window.innerHeight }
  const overSize = 1;
  const rate = 9 / 16;
  const w = containerSize.w * overSize;
  const h = containerSize.h * overSize;
  const size = { width: w, height: w * rate, left: 0, top: 0 };
  if (size.height < h) {
    // stretch horizontally
    size.height = h;
    size.width = h / rate;
  }

  //console.log(size)
  size.left = -(size.width - containerSize.w) / 2;
  size.top = -(size.height - containerSize.h) / 2;
  return size;
}
