import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, 0, 80],
    height: 56,
    background: 'rgba(0, 100, 56, .9)',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   //height: [44, 0, 56],
  // })),
  logo: css(mq({
    img : {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: 0,
      transition: 'all 0.5s ease-in-out'
    }
  })),
  logoImg: css(mq({
    //height: [22, 0, 36],
    height: [86, 120, 150],
    //marginTop: [32, 68, 90]
  })),
  logoImg2: css(mq({
    //height: [22, 0, 36],
    //height: [86, 120, 150],
    height:56,
    opacity: '0'
  })),
  logoScrolled: css(mq({
    //marginTop:'-60%'
    '.logo1' : {
      top: '-270%'
    },
    '.logo2' : {
      opacity: '1'
    }
  }))
}