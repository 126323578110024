//import React from 'react';
import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
import { SiteLink } from '../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__bsyc', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
     css = {style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const screenSize = window.innerWidth;
  const index = props.idx;
  return (
    
    <div css = {style.pageTile__tile} className="pageTile__bsyc">
      <SiteLink className="pageTile__bsyc__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>
        { index % 2 === 0  || screenSize <= 550 ? <div className="pageTile__bsyc__bg" css = {style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div> : null }
        <div css = {style.tile__details} className="pageTile__bsyc__content__details">  
          <div className="pageTile__bsyc__container">
            <div className="pageTile__bsyc__title">
              {page.pageTitle}
            </div>
            <div className="pageTile__bsyc__desc">
              {page.tileDesc || page.description}
            </div>
          </div>
        </div>
        { index % 2 !== 0 && screenSize >= 550 ?  <div className="pageTile__bsyc__bg" css = {style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div> : null }
      </SiteLink>
    </div>

  )
}