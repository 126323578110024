import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import { Helmet } from "react-helmet-async";

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (!pageData) {
    return <NotFound />;
  }

  log("3. call rendering memo");
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  const description = cmsUtils.payload(pageData, "Description");
  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });
  let itemListElementSchema = [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://www.pgbgardens.com.au/",
        "name": "Home",
      },
    },
  ];
  if (pageData.pageUrl !== "/") {
    itemListElementSchema.push({
      "@type": "ListItem",
      "position": 2,
      "name": pageData.pageTitle
        .substring(0, pageData.pageTitle.indexOf("-"))
        .trim(),
    });
  }
  const breadcrumbStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": `https://www.pgbgardens.com.au${pageData.pageUrl}/#breadcrumb`,
    "itemListElement": itemListElementSchema,
  };
  const homeStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "PGB Gardens",
    "alternateName": "PGB",
    "brand": {
      "@type": "Brand",
      "name": "PGB Gardens",
    },
    "url": "https://www.pgbgardens.com.au",
    "logo": "https://www.pgbgardens.com.au/assets/logos/logo.png",
    "sameAs": [
      "https://www.instagram.com/pgbgardens",
      "https://www.facebook.com/people/Pgbgardens/100063607987662/?tn-str=k*F",
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "url":
        "https://www.google.com/search?q=pgb+gardens&sca_esv=82a31d96fe05b12d&sca_upv=1&rlz=1C1ONGR_enAU1082AU1082&sxsrf=ADLYWIJTJiM5HOoF6KVp2FkUIN6fhA_dFg%3A1720571414003&ei=FdaNZp7-PLaYseMPzb6n2AM&ved=0ahUKEwje3MT_m5uHAxU2TGwGHU3fCTsQ4dUDCA8&uact=5&oq=pgb+gardens&gs_lp=Egxnd3Mtd2l6LXNlcnAiC3BnYiBnYXJkZW5zMgsQLhiABBjHARivATICECYyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMhoQLhiABBjHARivARiXBRjcBBjeBBjgBNgBAUiaClCSBVj9B3ABeACQAQCYAbgBoAGOBKoBAzAuM7gBA8gBAPgBAZgCA6AC6ALCAgoQIxiwAhiwAxgnwgILEAAYgAQYsAMYogTCAgsQABiwAxiiBBiJBcICERAuGIAEGJECGMcBGIoFGK8BwgIgEC4YgAQYkQIYxwEYigUYrwEYlwUY3AQY3gQY4ATYAQGYAwCIBgGQBga6BgYIARABGBSSBwMxLjKgB_cT&sclient=gws-wiz-serp#lrd=0x6ab0cb9187bdd113:0xfc0e61821b020170,1,,,,",
      "ratingValue": "5.0",
      "reviewCount": "26",
    },
    "areaServed": {
      "@type": "State",
      "name": "SA",
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Campbelltown",
      "addressRegion": "SA",
      "postalCode": "5074",
      "streetAddress": "28 Mines Rd",
    },
    "telephone": "+61435-602-920",
    "openingHours": ["Mo-Fr 8:00-17:00"],
    "keywords":
      "garden services, maintenance, turf installation, lawn mowing, hedging, pruning, slashing, PGB Gardens",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": " Adelaide Residential & Commercial Garden services",
      "description":
        "Garden services for residential and commercial properties in Adelaide. Hedging, pruning, turf installation, lawn mowing, slashing and maintenance services. No job is too big or small for us.",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Garden Maintenance - Hedging, Pruning, Lawns",
            "description":
              "With over ten years’ experience you are getting the garden you always wanted. We can offer a full maintenance package for your garden and work with you and your needs",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Hedging & Pruning",
            "description":
              "Pruning is an essential part of keeping a garden healthy and flourishing. If you prune correctly, you will encourage new growth and your plants will stay more resilient, flower better and grow stronger.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Turf Installation & Lawn Maintenance",
            "description":
              "Turf Installation services in Adelaide by PGB Gardens is professional service for gardening and lawn maintenance so that you can come home to your beautiful, green, manicured property. Oh what a relief that will be!",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Pruning & Garden Services",
            "description":
              "Pruning is an essential part of keeping a garden healthy and flourishing. If you prune correctly, you will encourage new growth and your plants will stay more resilient, flower better and grow stronger. Plants like a good prune when the time is right.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Commercial Garden Services",
            "description":
              "Services for commercial properties in Adelaide. Hedging, pruning, turf installation, lawn mowing, slashing and maintenance services. We do our best to accommodate all requests promptly. We also have numerous contracts with different commercial companies if a reference is required. ",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Slashing Blocks",
            "description":
              "Is your large block overgrowing with grass and weeds?  specialises in slashing and clearing large blocks, no matter what their condition. We are not scared to roll up our sleeves and tackle the weeds and grasses that are out of control on your block.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Residential - Hedging, Pruning, Lawns",
            "description":
              "Services for residential properties in Adelaide include hedging, pruning, turf installation, lawn mowing, slashing and maintenance services. Allow us to provide a professional service so that you can come home to your beautiful, green, manicured property.",
          },
        },
      ],
      "url": "https://www.pgbgardens.com.au",
    },
  };
  const pageStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": utils.site.resourcePath(pageData.pageUrl),
    "url": utils.site.resourcePath(pageData.pageUrl),
    "name": pageData.pageTitle,
    "description": description,
    "datePublished": pageData.datePublished,
    "dateModified": pageData.dateModified,
    "inLanguage": "en-AU",
    "isPartOf": {
      "@type": "WebSite",
      "@id": "https://www.pgbgardens.com.au/",
      "url": "https://www.pgbgardens.com.au/",
      "name":
        "Garden Services - Adelaide Residential & Commercial | PGB Gardens",
      "description":
        "Garden services for residential and commercial properties in Adelaide. Hedging, pruning, turf installation, lawn mowing, slashing and maintenance services. No job is too big or small for us.",
      "inLanguage": "en-AU",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate":
            "https://www.pgbgardens.com.au/?s={search_term_string}",
        },
        "query-input": {
          "@type": "PropertyValueSpecification",
          "valueRequired": "http://schema.org/True",
          "valueName": "search_term_string",
        },
      },
    },
    "potentialAction": {
      "@type": "ReadAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `https://www.pgbgardens.com.au${pageData.pageUrl}`,
      },
    },
  };
  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      {pageData.pageUrl.startsWith("/") && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(homeStructuredData)}
          </script>
        </Helmet>
      )}
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(pageStructuredData)}
        </script>
        <script type='application/ld+json'>
          {JSON.stringify(breadcrumbStructuredData)}
        </script>
      </Helmet>
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName;

  return {
    title: pageData.pageTitle,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.resourcePath(
      cmsUtils.payload(pageData, "ImageUrl")
    ),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.resourcePath(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
